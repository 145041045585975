
import { Component, Vue } from 'vue-property-decorator'
import { parseTime } from '@/utils/filters'
import { getJobPage, removeJob, JobInfo } from '@/api/jobs'

import InputSearch from '@/components/InputSearch.vue'
import type { IPagination } from '@/utils/interface'
import { OptionModule } from '@/store/modules'

@Component({
  components: { InputSearch },
  name: 'Table',
  filters: { parseTime },
})
export default class extends Vue {
  listLoading = true
  listQuery = {
    filter: '',
  }

  /** 分页器 */
  pagination: IPagination<JobInfo> = {
    total: 0,
    pageNum: 1,
    pageSize: 10,
    list: [],
  }

  filterValue: string[] = []
  /** 部门数据 */
  get officeList() {
    return OptionModule.officeList
  }

  async getList() {
    this.listLoading = true
    const { pageNum, pageSize } = this.pagination
    const { recordList, totalCount } = await getJobPage({
      ...this.listQuery,
      pageNum,
      pageSize,
    })
    this.pagination.list = recordList
    this.pagination.total = totalCount
    this.listLoading = false
  }

  filterHandlerOffice(value: any, row: any) {
    return row.ownerOfficeId === value
  }

  onSearch(val: string) {
    // console.log('val', val)
    this.listQuery.filter = val
    this.pagination.pageNum = 1
    this.getList()
  }

  /** 新增 */
  onAdd() {
    this.$router.push({ name: 'jobAdd' })
  }

  /** 编辑 */
  onEdit(item: JobInfo) {
    this.$router.push({ name: 'jobEdit', params: { id: item.positionId } })
  }

  /** 删除 */
  onRemove(item: JobInfo) {
    removeJob(item.positionId).then(() => {
      this.$message({ message: '删除成功', type: 'success' })
      this.getList()
    })
  }

  /** 新增候选人 */
  onAddCandidate(item: JobInfo) {
    this.$router.push({ name: 'check', params: { officeId: item.positionId } })
  }

  /** 查看订单 */
  onShowOrder(item: JobInfo) {
    this.$router.push({ name: 'order', params: { officeId: item.positionId, positionId: item.positionId } })
  }

  created() {
    const { params } = this.$route
    if (params.officeId) this.filterValue = [params.officeId]

    this.getList()
  }
}
